<template>
  <div id="indexFrame">
    <div class="menu">
      <div class="logo">
        <el-image
          :src="require('@/assets/images/logo.png')"
          fit="contain"
        ></el-image>
      </div>
      <el-menu
        :default-active="currenPath"
        background-color="#001F46"
        text-color="#ffffff"
        active-text-color="#ffffff"
        router
        unique-opened
      >
        <div class="item" v-for="item of menuList" :key="item.path">
          <el-submenu :index="item.path" v-if="item.children">
            <template slot="title">
              <el-image :src="item.img" fit="contain"></el-image>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
              :index="items.path"
              v-for="items of item.children"
              :key="items.path"
              >{{ items.name }}
            </el-menu-item>
          </el-submenu>
          <el-menu-item :index="item.path" v-else>
            <el-image :src="item.img" fit="contain"></el-image>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </div>
      </el-menu>
    </div>
    <main class="main-container">
      <header class="main-header">
        <div class="title">
          <!--          管理中心-->
        </div>
        <div class="user">
          <el-badge :is-dot="isMessage" @click.native="openMessage">
            <i class="el-icon-message-solid" v-if="isMessage"></i>
            <i class="el-icon-bell" v-else></i>
          </el-badge>
          <el-dropdown
            @command="handleCommand"
            v-if="buttonList.includes('global-localChange')"
          >
            <span
              >{{ isLocal ? "内网" : "外网" }} <i class="el-icon-bangzhu"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="isLocalChange" v-if="!isLocal"
                >内 网</el-dropdown-item
              >
              <el-dropdown-item v-else command="isLocalChange"
                >外 网</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="handleCommand">
            <span
              >{{ user ? user.userName : "" }}
              <i class="iconfont icon-yonghu"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="updatePassword"
                >修改密码</el-dropdown-item
              >
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </header>
      <div class="main-content">
        <router-view />
      </div>
    </main>
    <messageDialog ref="messageDialog" @refreshMessage="refreshMessage" />
    <updatePasswordDialog ref="updatePasswordDialog" />
  </div>
</template>
<script>
import menu from "@/utils/menu.js";
import upload from "element-ui/packages/upload";

export default {
  components: {
    messageDialog: () => import("@/components/messageDialog/messageDialog.vue"),
    updatePasswordDialog: () => import("@/components/updatePasswordDialog.vue"),
  },
  data() {
    return {
      currenPath: "/index",
      menuList: [],
      isMessage: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.refreshRoute();
      },
      deep: true,
    },
  },
  created() {
    let controlAuthority = JSON.parse(
      sessionStorage.getItem("controlAuthority")
    );
    if (controlAuthority) {
      this.$store.commit("SET_CONTROLAUTHORITY", controlAuthority);
      let menuList = controlAuthority.menuList;
      for (let item of menu) {
        if (menuList.includes(item.path)) {
          if (item.children) {
            let children = [];
            for (let items of item.children) {
              if (menuList.includes(items.path)) {
                children.push(items);
              }
            }
            this.menuList.push({
              name: item.name,
              path: item.path,
              img: item.img,
              children: children.length ? children : null,
            });
          } else {
            this.menuList.push(item);
          }
        }
      }
    } else {
      this.$store.commit("RESET_USER");
      this.$router.replace({ name: "login" });
    }
    this.refreshRoute();
    this.initSelect();
  },
  computed: {
    upload() {
      return upload;
    },
    user() {
      return this.$store.state.user;
    },
    ws() {
      return this.$store.state.socket.ws;
    },
    isLocal() {
      return this.$store.state.isLocal;
    },
  },
  methods: {
    refreshMessage(status) {
      this.isMessage = status;
    },
    openMessage() {
      if (this.isMessage) {
        this.$refs.messageDialog.getPlayerUrl();
      } else {
        this.$message.warning("暂无消息");
      }
    },
    initSelect() {
      this.$store.dispatch("select/getParkingLotList");
      this.$store.dispatch("select/getBusinessList");
    },
    refreshRoute() {
      let fullPath = this.$route.fullPath;
      let pathArr = fullPath.split("/").splice(1);

      if (pathArr[0] === "advertise" && pathArr.length > 1) {
        this.currenPath = `/${pathArr[0]}/${pathArr[1]}`;
      } else if (pathArr[1] === "merchant") {
        this.currenPath = `/${pathArr[0]}/list`;
      } else if (pathArr[1] === "list") {
        this.currenPath = `/${pathArr[0]}/${pathArr[1]}`;
      } else {
        this.currenPath = this.$route.fullPath;
      }
    },
    handleCommand(command) {
      this[command]();
    },
    isLocalChange() {
      this.$store.commit("SET_ISLOCAL", !this.isLocal);
    },
    updatePassword() {
      this.$refs.updatePasswordDialog.show = true;
    },
    loginOut() {
      this.$store.commit("RESET_USER");
      this.$router.replace({ name: "login" });
      this.ws && this.ws.close();
    },
  },
};
</script>
<style lang="scss">
#indexFrame {
  width: 100%;
  min-width: 1450px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: white;
  background-color: #283b63;

  .menu {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    box-sizing: border-box;
    background-color: #001f46;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    & > .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 30px 0 0 0;

      .el-image {
        width: 75%;
        font-size: 0;
        margin-bottom: 20px;
      }

      p {
        color: white;
      }
    }

    & > .el-menu {
      border: none;

      .item {
        margin-bottom: 10px;

        & > .el-menu-item {
          height: 40px;
          line-height: 40px;
          width: 90%;
          margin: 0 auto;

          .el-image {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }

        .el-submenu {
          .el-submenu__title {
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            width: 90%;
            margin: 0 auto;

            .el-image {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }

          .el-menu--inline {
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              text-align: center;

              &:first-child {
                margin-top: 10px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }

            .is-active {
              background-color: rgba($color: #ffffff, $alpha: 0.3) !important;
              border-radius: 0;
              position: relative;
              color: $main-color !important;

              &::after {
                content: "";
                display: inline-block;
                position: absolute;
                right: 0;
                top: 0;
                width: 4px;
                height: 100%;
                background-color: $main-color;
              }
            }
          }
        }

        .el-menu-item.is-active {
          background-color: $main-color !important;
          border-radius: 5px;
        }

        .is-active.is-opened {
          .el-submenu__title {
            background-color: $main-color !important;

            i {
              color: white !important;
            }
          }
        }

        .el-submenu__icon-arrow,
        .el-icon-arrow-down {
          color: white;
        }
      }
    }
  }

  .main-container {
    position: relative;
    width: calc(100% - 200px);

    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #001f46;
      padding: 10px 20px;

      .title {
        i {
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .user {
        color: white;

        i {
          margin-right: 10px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }

        span {
          cursor: pointer;
          margin-left: 20px;
          margin-right: 5px;
          color: white;
        }
      }
    }

    .main-content {
      padding: 20px;

      .el-breadcrumb {
        margin-bottom: 20px;

        .el-breadcrumb__item {
          .el-breadcrumb__inner {
            color: rgba(255, 255, 255, 0.71);
          }

          &:last-child {
            .el-breadcrumb__inner {
              color: white;
            }
          }
        }
      }

      .el-tabs {
        .el-tabs__item {
          color: white;
          &.is-active {
            color: $main-color;
          }
        }
      }

      .table-container {
        padding: 15px 10px;
        //background-color:#001F46;

        .table-filtrate {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
              margin-bottom: 15px;
              margin-right: 20px;

              &:last-child {
                margin-right: 0;
              }

              .resetBtn {
                border-color: $main-color;
                color: $main-color;
                background-color: transparent;
              }

              .label {
                margin-right: 10px;
              }

              .el-input {
                width: 170px;

                .el-input-group__append {
                  background-color: $main-color;

                  i {
                    color: white;
                  }
                }
              }

              .el-date-editor.el-input {
                width: 190px;
              }

              .el-button {
                height: 32px;
                display: flex;
                align-items: center;
              }
            }
          }
          .create_btn {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
            &.is-disabled {
              color: #c0c4cc;
              background-image: none;
              background-color: #ffffff;
              border-color: #ebeef5;
            }
          }
        }

        .table-title {
          display: flex;
          align-items: center;
          color: #142239;
          margin-bottom: 14px;

          & > span,
          .dot {
            display: inline-block;
            width: 7px;
            height: 7px;
            background-color: #00cec1;
            border-radius: 50%;
            margin-right: 3px;
          }
        }

        .el-table {
          border: 0;
          background-color: transparent;
          &::before {
            background-color: #31546f;
          }
          tr {
            background-color: transparent;
          }
          .el-table__header-wrapper {
            .el-table__header {
              tr {
                th {
                  background-color: #006174;
                  border-color: #5799a7;
                  .cell {
                    color: white;
                    font-weight: bold;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
          .el-table__body-wrapper {
            &:after,
            &:before {
              content: "";
              display: inline-block;
              width: 1px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: #31546f;
            }
            &:before {
              top: 0;
              left: initial;
              right: 0;
            }
            .el-table__row {
              &:hover {
                td.el-table__cell {
                  background-color: unset;
                }
              }
            }
            .el-table__body {
              td.el-table__cell {
                border-color: #31546f;
                .cell {
                  color: white;
                  .el-table__expand-icon {
                    color: white;
                  }
                }
              }
              .el-table__expanded-cell {
                background-color: transparent;
              }
            }
          }
        }
      }

      .el-input__inner,
      .el-range-input,
      .el-range-separator {
        background-color: transparent;
        border-color: $main-color;
        color: $main-color;
      }
      .el-input {
        .el-input-group__append,
        .el-input-group__prepend {
          border-color: $main-color;
        }
      }
      .el-input.is-disabled .el-input__inner {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #c0c4cc;
      }

      .pagination {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        .el-pagination__total,
        .el-pagination__jump,
        .el-input__inner,
        .el-pager li {
          color: white;
        }
        .btn-prev,
        .btn-next {
          background-color: transparent;
          color: white;
        }
        button[disabled] {
          color: #a0a4ac;
        }
        .el-pager {
          li {
            background-color: transparent;
            &.active {
              color: $main-color;
            }
          }
        }
        .el-pagination__jump {
          .el-input__inner {
            background-color: transparent;
            border: none;
          }
        }
      }

      .basicsInfo {
        background-color: $main-background;
        padding: 15px;
        margin-bottom: 20px;

        h3 {
          color: white;
          position: relative;
          padding-left: 10px;
          margin-bottom: 20px;
          font-weight: initial;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: $main-color;
          }
        }

        .info {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          li {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.66);
            margin-right: 20px;
            margin-bottom: 20px;

            .label {
              display: inline-block;
              width: 100px;
              text-align: right;
              color: white;
              font-weight: 500;
            }

            .custom-module {
              width: 250px;
              padding: 0 5px 0 16px;
              height: 32px;
              line-height: 32px;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .icon-bianji {
                color: $main-color;
                cursor: pointer;
              }
            }
          }
        }
      }

      & > .report {
        .statisticalAnalysis {
          height: 100%;
          padding: 0 0.63rem 1.25rem 0.63rem;
          box-sizing: border-box;
          border-radius: 0.5rem;
          //box-shadow: 0 0.3rem 0.59rem 0 #f4f5f5;
          background: #001f46;
          & > .title-filter {
            height: 3.5rem;
            border-bottom: 1px solid #2f526c;
            margin-bottom: 1.25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
              font-size: 1.1rem;
              color: white;
              font-weight: initial;
              white-space: nowrap;
            }

            .filter {
              display: flex;

              li {
                margin-right: 1rem;

                &:last-child {
                  margin-right: 0;
                }

                .el-input__inner,
                .el-input__icon,
                .el-range-separator {
                  height: 2rem;
                  line-height: 2rem;
                }

                .typeSelect {
                  display: inline-block;
                  border: 1px solid rgba(19, 209, 196, 0.24);
                  border-radius: 0.34rem;
                  white-space: nowrap;

                  & > span {
                    display: inline-block;
                    padding: 0.5rem 0.75rem;
                    color: white;
                    cursor: pointer;
                    transition: all 0.3s;
                    font-size: 1rem;
                  }

                  .active {
                    color: white;
                    background: #00cec0;
                    border-radius: 0.34rem;
                  }
                }
              }
            }
          }

          & > .chartDetail {
            height: calc(100% - 4.75rem - 1px);
            background: transparent;
          }
        }
      }
    }
  }
}
</style>
